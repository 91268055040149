<template>
  <div class="pa-4">
    <v-card tile>
      <v-card-text class="pa-0">
        <v-data-table
          :loading="loadingItems"
          :headers="headers"
          :items="getTrafficLocation"
          :items-per-page="50"
          :page.sync="filter['page']"
          item-key="id"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.key`]="{ item }">
            {{ `${capitalize(item.key)}` }}
          </template>
          <template v-slot:[`item.session_count.value`]="{ item }">
            <v-row>
              <v-col cols="1 d-flex">
                {{ `${item.session_count.value}` }}
              </v-col>
              <v-col cols="8 d-flex ma-auto">
                <v-progress-linear :value="getProgress(item.session_count.value)"></v-progress-linear>
              </v-col>
              <v-col cols="2 d-flex">
                {{ `${getProgress(item.session_count.value)}%` }}
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Material from 'vuetify/es5/util/colors';
import moment from 'moment';
export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
    },
    stores: Array,
    timezone: String,
  },
  data() {
    return {
      loadingItems: false,
      selectedItem: null,
      serverItemsLength: 0,
      showFilter: true,
      status: [
        {
          key: 'Active',
          value: '1',
        },
        {
          key: 'Inactive',
          value: '0',
        },
      ],
      filter: {
        page: 1,
      },
      footerProps: {
        itemsPerPageOptions: [20, 50, 100],
      },
      headers: [
        {
          text: 'LOCATION',
          value: 'key',
        },
        {
          text: 'UNIT VISITORS',
          value: 'session_count.value',
        },
      ],
      color: Material,
    };
  },
  created() {
    this.fetchRecords();
  },
  computed: {
    ...mapGetters(['getTrafficLocation', 'getTotalTrafficLocation']),
  },
  watch: {
    '$route.query': {
      handler(query) {
        const filter = this.updateFilterQuery(query);
        this.fetchRecords(filter);
      },
      immediate: true,
    },
  },
  methods: {
    updateFilterQuery(query) {
      const filter = Object.assign(this.filter, this.transformQuery(query));
      return filter;
    },
    transformQuery(query) {
      const numbers = ['page'];
      for (let key in query) {
        if (numbers.includes(key)) {
          const val = query[key] ? parseInt(query[key]) : query[key];
          query[key] = val;
        }
      }
      return query;
    },
    fetchRecords(filters) {
      this.loadingItems = true;
      let date = moment()
        .tz(this.timezone)
        .format('YYYY-MM-DD');
      this.$store
        .dispatch('trafficLocation', {
          ...filters,
          stores: this.stores,
          timezone: this.timezone,
          fromTime: this.dateRange.from || date,
          toTime: this.dateRange.to || date,
        })
        .then(() => {
          this.loadingItems = false;
        })
        .catch(() => {
          window._VMA.$emit('SHOW_SNACKBAR', {
            show: true,
            text: 'Auth Failed',
            color: 'error',
          });
          this.loadingItems = false;
          this.loading = false;
        });
    },
    getProgress(value) {
      let percentage = Math.round((value / this.getTotalTrafficLocation) * 100);
      return percentage;
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
  },
};
</script>
