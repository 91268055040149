<template>
  <div class="sub-chart-container">
    <v-hover v-slot="{ hover }">
      <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 16 : 2" height="100%">
        <v-card-text>
          <h2 class="l-chart-title text-black">Average order items</h2>
          <h1 class="text-black">{{ aoi }}</h1>
          <template>
            <v-simple-table class="tbl-total">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-left text-bold text-black text-small">Online store</td>
                    <td class="text-right">{{ onlineStoreAoi }}</td>
                  </tr>
                  <tr style="display: none;">
                    <td class="text-left text-black text-small">AOI via Boost Upsell</td>
                    <td class="text-right">{{ uselAoi }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <h3 class="my-3 text-small text-bold">ORDERS OVER TIME</h3>
          <LineChart v-if="isLoading" :chartdata="chartAoiData" :options="chartAoiOpts" :height="200" />
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import LineChart from '@/components/chart/Line';
import { FETCH_AOI_RATE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';

export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
    },
    stores: Array,
    timezone: String,
  },
  components: {
    LineChart,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted: async function() {
    this.isLoading = true;
    let { startDate, endDate, stores } = this.loadQueryFromCache();
    let fromTime = startDate
      ? startDate
      : this.$props.dateRange && this.$props.dateRange.from
      ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let toTime = endDate
      ? endDate
      : this.$props.dateRange && this.$props.dateRange.to
      ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
      : moment()
          .tz(this.timezone)
          .format('YYYY-MM-DD');
    let _stores = stores ? stores : this.$props.stores ? this.$props.stores : '';
    await this.$store.dispatch(FETCH_AOI_RATE, { fromTime, toTime, stores: _stores, timezone: this.timezone });
    this.isLoading = true;
  },
  computed: {
    ...mapGetters(['chartAoiData', 'chartAoiOpts']),
    aoi() {
      return this.$store.getters.aoi;
    },
    uselAoi() {
      return this.$store.getters.uselAoi;
    },
    onlineStoreAoi() {
      return this.$store.getters.onlineStoreAoi;
    },
  },
  methods: {
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
  },
  watch: {
    dateRange: async function(newTime, currTime) {
      let fromTime = newTime
        ? moment(newTime.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime =
        newTime && newTime.to
          ? moment(newTime.to).format('YYYY-MM-DD')
          : moment()
              .tz(this.timezone)
              .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_AOI_RATE, {
        fromTime,
        toTime,
        stores: this.stores,
        timezone: this.timezone,
      });
    },
    stores: async function(newStores, currStores) {
      let { startDate, endDate } = this.loadQueryFromCache();
      let fromTime = startDate
        ? startDate
        : this.$props.dateRange && this.$props.dateRange.from
        ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      let toTime = endDate
        ? endDate
        : this.$props.dateRange && this.$props.dateRange.to
        ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone)
            .format('YYYY-MM-DD');
      await this.$store.dispatch(FETCH_AOI_RATE, { fromTime, toTime, stores: newStores, timezone: this.timezone });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-chart-container {
  td {
    border-bottom: none !important;
    padding: 5px !important;
    margin: 0;
    height: auto !important;
  }
}
.v-card.v-sheet {
  border-radius: 12px;
}
</style>
