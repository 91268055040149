<template>
  <div class="sub-chart-container">
    <v-hover v-slot="{ hover }">
      <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 16 : 2" height="100%">
        <v-card-text>
          <h2 class="l-chart-title text-black">Abandoned checkouts recovery</h2>
          <template>
            <div class="d-flex flex-row mb-3">
              <h3 class="text-center mr-3">{{ abcheckoutConversionRate }}%</h3>
              <v-divider vertical></v-divider>
              <div>
                <h5 class="mb-0 mr-3 ml-3">{{ abcheckoutCompletedOrder }}</h5>
                <h5 class="mb-0 mr-3 ml-3">Order</h5>
              </div>
              <v-divider vertical></v-divider>
              <div>
                <h5 class="mb-0 mr-3 ml-3">{{ currency.priceFormat(abCheckoutRevenue) }}</h5>
                <h5 class="mb-0 mr-3 ml-3">Revenue</h5>
              </div>
            </div>
            <a href="https://lattehub.com/help/article/61b9aaf52a32f7000af7d605/"
              >How to recover abandoned checkouts to improve conversion rate?</a
            >
          </template>
          <h3 class="my-3 text-small text-bold">EMAIL CONVERSION FUNNEL</h3>
          <Bar v-if="!isLoading" :chartdata="abcheckoutConversion" :options="abcheckoutChartOptions" :height="226" />
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Bar from '@/components/chart/Bar';
import { FETCH_ABCHECKOUT_CONVERSION } from '@/store/actions.type';
import currency from '@/helpers/currency';

export default {
  props: {
    dateRange: {
      from: Date,
      to: Date,
      timezone: String,
    },
    stores: Array,
    timezone: String,
  },
  components: {
    Bar,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      filters: {
        capturedDate: {
          startDate: '',
          endDate: '',
          timezone: '',
        },
        stores: '',
        timezone: 'America/Los_Angeles',
        referenceMethod: 'email',
      },
      currency,
    };
  },
  async created() {
    await this.createFilterObj();
    await this.fecthData();
  },
  methods: {
    createFilterObj() {
      const { stores, capturedDate, referenceMethod } = this.$route.query;
      this.filters.capturedDate = capturedDate
        ? capturedDate
        : {
            startDate: this.$props.dateRange.from
              ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
              : moment()
                  .tz(this.timezone)
                  .format('YYYY-MM-DD'),
            endDate: this.$props.dateRange.to
              ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
              : moment()
                  .tz(this.timezone)
                  .format('YYYY-MM-DD'),
            timezone: this.$props.timezone ? this.$props.timezone : 'America/Los_Angeles',
          };
      this.filters.referenceMethod = referenceMethod ? referenceMethod : 'email';
      this.filters.stores = stores
        ? stores
        : this.$props.stores && this.$props.stores.length
        ? this.$props.stores.join(',')
        : localStorage.getItem('store-id');
      return this.filters;
    },
    async fecthData() {
      this.isLoading = true;
      await this.$store.dispatch(FETCH_ABCHECKOUT_CONVERSION, this.filters);
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(['abcheckoutConversion', 'abcheckoutChartOptions']),
    abcheckoutCompletedOrder() {
      return this.$store.getters['abcheckoutCompletedOrder'];
    },
    abCheckoutRevenue() {
      return this.$store.getters['abCheckoutRevenue'];
    },
    abcheckoutConversionRate() {
      return this.$store.getters['abcheckoutConversionRate'];
    },
  },
  watch: {
    '$route.query': function() {
      this.createFilterObj();
      this.fecthData();
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-chart-container {
  td {
    border-bottom: none !important;
    padding: 5px !important;
    margin: 0;
    height: auto !important;
  }
}
.v-card.v-sheet {
  border-radius: 12px;
}
</style>
