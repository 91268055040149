<template>
  <div class="auto-refresh-popover">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-switch
            v-model="refresh"
            :label="`Auto refresh`"
            inset
            @change="onChange"
            color="primary"
            outlined
          ></v-switch>
        </div>
      </template>
      <span>Automatically update statistics every 5 minutes.</span>
    </v-tooltip>
  </div>
</template>

<script>
import STORAGE_NAME from '@/const/storage';

export default {
  props: {
    onStateChange: Function,
  },
  data: function() {
    // let { autoRefresh } = this.loadQueryFromCache();
    let query = this.$route.query;
    return {
      refresh: query?.refresh === 'true' || false,
    };
  },
  methods: {
    onChange() {
      this.$props.onStateChange(this.refresh);
    },
    // loadQueryFromCache() {
    //   let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    //   return cacheQuery ? JSON.parse(cacheQuery) : {};
    // },
  },
};
</script>

<style scoped>
.auto-refresh-popover {
  margin-left: 16px;
  min-width: 150px;
}
</style>
