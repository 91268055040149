<template>
  <div v-if="getTimezoneDone">
    <div class="analytic-container">
      <v-container>
        <h1 class="analytic-heading">
          Overview dashboard
        </h1>
      </v-container>
      <v-container>
        <div class="analytic-actions-wrapper has-select-store has-auto-refresh">
          <SelectStorePopover :storeSelectWatcher="onSelectedStores" />
          <PickDatePopover :handler="pickedDate" :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'" />
          <AutoRefreshPopover :onStateChange="onAutoRefreshChange" />
        </div>
      </v-container>
      <v-row no-gutters class="l-chart-container">
        <v-col cols="12" sm="12" md="6">
          <TotalSales
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <ConversionRate
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <TotalOrders
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <AverageOrderValue
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <AverageOrderItems
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <AbandonedCheckoutsRecovery
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <TrafficSourceTable
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <TrafficLocationTable
            :dateRange="dateRange"
            :stores="stores"
            :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
          />
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else class="analytic-loading">
    <Loading />
  </div>
</template>

<script>
import AbandonedCheckoutsRecovery from './components/AbandonedCheckoutsRecovery';
import ConversionRate from './components/ConversionRate';
import TotalOrders from './components/TotalOrders';
import TotalSales from './components/TotalSales';
import SelectStorePopover from './components/SelectStorePopover';
import PickDatePopover from './components/PickDatePopover';
import AverageOrderValue from './components/AverageOrderValue';
import AverageOrderItems from './components/AverageOrderItems';
import Loading from '@/components/Loading';
import TrafficSourceTable from './components/TrafficSourceTable';
import AutoRefreshPopover from './components/AutoRefreshPopover';
import TrafficLocationTable from './components/TrafficLocationTable';
import moment from 'moment';
import STORAGE_NAME from '@/const/storage';
import {
  FETCH_CONVERSION_RATE,
  FETCH_TOTAL_ORDERS,
  FETCH_TOTAL_SALES,
  FETCH_AOV_RATE,
  FETCH_AOI_RATE,
  FETCH_PAGE_PREFERRENCE,
} from '@/store/actions.type';

export default {
  components: {
    AbandonedCheckoutsRecovery,
    Loading,
    AverageOrderValue,
    AverageOrderItems,
    PickDatePopover,
    ConversionRate,
    TotalOrders,
    TotalSales,
    SelectStorePopover,
    AutoRefreshPopover,
    TrafficSourceTable,
    TrafficLocationTable,
  },

  data: function() {
    let query = this.$route.query;
    console.log(query?.refresh === 'true');
    return {
      width: 2,
      autoRefresh: query?.refresh === 'true' || false,
      refreshInterval: false,
      dateRange: {
        from: query?.from_time,
        to: query?.to_time,
      },
      stores: query?.store?.split(',') || [],
      getTimezoneDone: true,
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  created() {
    // let { autoRefresh } = this.loadQueryFromCache();
    if (this.refresh) this.autoRefreshChart();
  },
  methods: {
    pickedDate(dates) {
      this.dateRange = {
        from: dates.startDate,
        to: dates.endDate,
      };
      let query = this.$route.query;
      this.updateQuery({
        stores: query?.stores,
        from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
        to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
        tz: query?.tz,
        refresh: query?.refresh,
      });
      // this.cacheQuery({ startDate: this.dateRange.from, endDate: this.dateRange.to });
    },
    onAutoRefreshChange(autoRefresh) {
      this.refresh = autoRefresh;
      let query = this.$route.query;
      this.updateQuery({
        stores: query?.stores,
        from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
        to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
        tz: query?.tz,
        refresh: this.refresh,
      });
      // this.cacheQuery({ autoRefresh: autoRefresh.toString() });
      if (this.refresh) {
        this.autoRefreshChart();
      } else {
        clearInterval(this.refreshInterval);
      }
    },
    onSelectedStores(stores) {
      this.stores = stores;
      let query = this.$route.query;
      this.updateQuery({
        stores: this.stores.join(','),
        from_time: query?.from_time,
        to_time: query?.to_time,
        tz: query?.tz,
        refresh: query?.refresh,
      });
      // this.cacheQuery({ stores: this.stores });
    },
    autoRefreshChart() {
      this.refreshInterval = setInterval(async () => {
        let { stores, startDate, endDate } = this.loadQueryFromCache();
        if (!startDate) startDate = moment().format('YYYY-MM-DD');
        await Promise.all([
          this.$store.dispatch(FETCH_CONVERSION_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_TOTAL_ORDERS, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_TOTAL_SALES, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_AOV_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
          this.$store.dispatch(FETCH_AOI_RATE, {
            fromTime: startDate,
            toTime: endDate,
            stores,
            timezone: this.pagePreference.standardTimezone,
          }),
        ]);
      }, 60 * 5 * 1000); // 5 minutes
    },
    // cacheQuery(args) {
    //   let currentStorageStr = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    //   let currentStorage = currentStorageStr ? JSON.parse(currentStorageStr) : {};
    //   currentStorage.startDate =
    //     args && args.startDate ? moment(args.startDate).format('YYYY-MM-DD') : currentStorage.startDate;
    //   currentStorage.endDate =
    //     args && args.endDate ? moment(args.endDate).format('YYYY-MM-DD') : currentStorage.endDate;
    //   currentStorage.stores = args && args.stores ? args.stores : currentStorage.stores;
    //   currentStorage.autoRefresh = args && args.autoRefresh ? args.autoRefresh : currentStorage.autoRefresh;
    //   localStorage.removeItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    //   localStorage.setItem(STORAGE_NAME.REPORT_SEARCH_QUERY, JSON.stringify(currentStorage));
    // },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
    updateQuery(query) {
      this.$router.push({
        query: query,
      });
    },
  },
  computed: {
    // ...mapGetters(['pagePreference']),
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
  destroyed() {
    // No remove auto refresh
    let currentCacheQuery = this.loadQueryFromCache();
    let newCacheQuery = { autoRefresh: currentCacheQuery?.autoRefresh };
    localStorage.removeItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
    localStorage.setItem(STORAGE_NAME.REPORT_SEARCH_QUERY, JSON.stringify(newCacheQuery));
  },
};
</script>

<style lang="scss">
.sub-chart-container {
  transition: all 0.1s;
}
.upsell-page {
  height: 100% !important;
  .upsell-page-container {
    @include flex(center, flex-center);

    & .upsell-page-content {
      max-width: 1024px;
      min-width: 800px;
      margin-top: 40px;
    }
  }
}
@media (min-width: 768px) {
  .analytic-actions-wrapper.has-select-store {
    grid-template-columns: repeat(3, -webkit-min-content);
    grid-template-columns: repeat(3, min-content);
  }
  .analytic-actions-wrapper.has-select-store .select-store-popover {
    grid-row-start: 1;
    grid-column: 1/3;
  }

  .analytic-actions-wrapper.has-select-store .pick-date-popover {
    grid-row-start: 1;
    grid-column: 2/3;
  }
  .analytic-actions-wrapper.has-select-store .auto-refresh-popover {
    grid-row-start: 1;
    grid-column: 3/3;
  }
}
.analytic-container .analytic-actions-wrapper {
  display: grid;
  grid-gap: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.analytic-container .analytic-actions-wrapper.has-auto-refresh .select-store-popover {
  grid-row-start: 1;
  grid-column: 1/2;
}
.analytic-container .analytic-actions-wrapper.has-select-store .pick-date-popover {
  grid-row-start: 1;
  grid-column: 2/3;
}
.analytic-container .analytic-actions-wrapper.has-select-store .auto-refresh-popover {
  grid-row-start: 1;
  grid-column: 3/3;
}
@media only screen and (max-width: 480px) {
  .analytic-actions-wrapper.has-select-store {
    grid-template-columns: repeat(2, -webkit-min-content);
    grid-template-columns: repeat(2, min-content);
  }
  .analytic-container .analytic-actions-wrapper.has-select-store .select-store-popover {
    grid-row-start: 1;
    grid-column: 1/2;
  }

  .analytic-container .analytic-actions-wrapper.has-select-store .pick-date-popover {
    grid-row-start: 2;
    grid-column: 1/2;
  }
  .analytic-container .analytic-actions-wrapper.has-select-store .auto-refresh-popover {
    grid-row-start: 1;
    grid-column: 2/2;
  }
}
.analytic-container .l-chart-container {
  .text-black {
    color: #000000;
  }
  .text-bold {
    font-weight: 700;
  }
  .text-small {
    font-size: 12px;
  }
  .l-chart-title {
    font-size: 16px;
  }
  .sub-chart-container {
    height: 100%;
    padding: 16px;
  }
  .sub-chart-container {
    // transform: scale(1.01);

    // margin: -8px;
    .on-hover {
      transition: all 0.5s;
    }
  }
}
.analytic-loading {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
